import * as React from "react";
import { graphql, HeadFC, PageProps } from "gatsby";
import { ListQuery } from "../../graphql-types";
import Cell from "../components/Cell";

type SectionOptions = "extras" | "missing";

interface BagdeProps {
  children: React.ReactNode | React.ReactNode[];
}

const Badge: React.FC<BagdeProps> = ({ children }) => {
  return (
    <div className="text-xs px-2 py-1 rounded-full bg-white dark:bg-stone-900">
      {children}
    </div>
  );
};

const IndexPage: React.FC<PageProps<ListQuery>> = ({ data }) => {
  const [section, setSection] = React.useState<SectionOptions>("extras");

  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [selectedOnly, setSelectedOnly] = React.useState<boolean>(false);

  const { allGoogleFaltantesSheet: missing, allGoogleRepetidasSheet: extras } =
    data;

  const missingNodes = missing.nodes.map((node) => ({
    ...node,
    selected: false,
  }));

  const extrasNodes = extras.nodes.map((node) => ({
    ...node,
    selected: false,
  }));

  const [missingStickers, setMissingStickers] = React.useState(missingNodes);
  const [extraStickers, setExtraStickers] = React.useState(extrasNodes);

  const [filteredMissingStickers, setFilteredMissingStickers] =
    React.useState(missingStickers);
  const [filteredExtraStickers, setFilteredExtraStickers] =
    React.useState(extraStickers);

  const handleSelected = (
    selected: boolean,
    code: string | null | undefined,
    list: SectionOptions
  ) => {
    if (list === "extras") {
      const updatedList = extraStickers.map((node, i) =>
        node.code === code ? { ...node, selected } : node
      );
      setExtraStickers(updatedList);
    }
    if (list === "missing") {
      const updatedList = missingStickers.map((node, i) =>
        node.code === code ? { ...node, selected } : node
      );
      setMissingStickers(updatedList);
    }
  };

  const handleSearch = (value: string, list: SectionOptions) => {
    console.log(value);
    setSearchQuery(value);
    if (value.length) {
      if (list === "extras") {
        const filteredList = extraStickers.filter(({ code, selected }) => {
          if (selectedOnly) {
            return (
              code?.toLowerCase().includes(value.toLowerCase()) && selected
            );
          }
          return code?.toLowerCase().includes(value.toLowerCase());
        });
        console.log(filteredList);
        setFilteredExtraStickers(filteredList);
      }
      if (list === "missing") {
        const filteredList = missingStickers.filter(({ code, selected }) => {
          if (selectedOnly) {
            return (
              code?.toLowerCase().includes(value.toLowerCase()) && selected
            );
          }
          return code?.toLowerCase().includes(value.toLowerCase());
        });
        console.log(filteredList);
        setFilteredMissingStickers(filteredList);
      }
    } else {
      if (selectedOnly) {
        showSelected();
      } else {
        setFilteredExtraStickers(extraStickers);
        setFilteredMissingStickers(missingStickers);
      }
    }
  };

  const showSelected = () => {
    setFilteredMissingStickers(
      [...missingStickers].filter((node) => node.selected)
    );
    setFilteredExtraStickers(
      [...extraStickers].filter((node) => node.selected)
    );
  };

  React.useEffect(() => {
    handleSearch(searchQuery, section);
  }, [missingStickers, extraStickers]);

  React.useEffect(() => {
    // if (searchQuery.length) {
    handleSearch(searchQuery, section);
    // }
  }, [section]);

  React.useEffect(() => {
    if (selectedOnly) {
      showSelected();
    } else {
      setFilteredMissingStickers(missingStickers);
      setFilteredExtraStickers(extraStickers);
    }
  }, [selectedOnly]);

  return (
    <div className="min-h-screen bg-stone-100 dark:bg-stone-800 max-w-3xl px-4 pt-24 my-0 mx-auto">
      <div className="fixed bg-stone-100 dark:bg-stone-800 w-full top-0 left-0">
        <nav className="grid grid-cols-2 font-medium text-sm py-4 px-4 max-w-3xl my-0 mx-auto">
          <button
            onClick={() => setSection("extras")}
            className={`flex gap-2 items-center justify-center text-stone-800 dark:text-stone-50 py-3 rounded-xl ${
              section === "extras" ? ` bg-stone-200 dark:bg-stone-750` : ``
            }`}
          >
            {`Repetidas`} <Badge>{extras.nodes.length}</Badge>
          </button>
          <button
            onClick={() => setSection("missing")}
            className={`flex gap-2 items-center justify-center text-stone-800 dark:text-stone-50 py-3 rounded-xl ${
              section === "missing" ? ` bg-stone-200 dark:bg-stone-750` : ``
            }`}
          >
            {" "}
            {`Faltantes`} <Badge>{missing.nodes.length}</Badge>
          </button>
        </nav>
      </div>

      <div className="flex gap-2">
        <input
          type="text"
          value={searchQuery}
          placeholder="Busca por letra o número..."
          onInput={(e) => handleSearch(e.currentTarget.value, section)}
          className="focus:ring-lime-600 dark:focus:ring-lime-300 focus:outline-lime-300 focus:outline-2 focus:outline w-full focus:outline-offset-0 focus:border-lime-300 focus:shadow-none bg-white dark:bg-stone-750 rounded-lg border-stone-200 dark:border-stone-700 border-solid border text-stone-900 dark:text-stone-50 py-2 px-4 placeholder:text-stone-400 dark:placeholder:text-stone-500 transition-all duration-100"
        />
        {searchQuery.length > 0 && (
          <button className="px-1" onClick={() => handleSearch("", section)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-7 h-7 text-stone-700 dark:text-stone-300"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        )}
      </div>
      <div className="flex items-center pt-4 pb-2">
        <input
          type="checkbox"
          checked={selectedOnly}
          id="selected-checkbox"
          onChange={() => setSelectedOnly(!selectedOnly)}
          className="w-4 h-4 text-lime-300 bg-white rounded border-stone-300 focus:ring-offset-0 focus:ring-lime-600 dark:focus:ring-lime-500 dark:ring-offset-gray-800 focus:ring-2 dark:bg-stone-700 dark:border-stone-600"
        />
        <label
          htmlFor="selected-checkbox"
          className="ml-2 text-sm font-medium text-stone-600 dark:text-stone-400 select-none"
        >
          Mostrar solamente seleccionadas
        </label>
      </div>
      <div className="pt-4 pb-6">
        {section === "extras" ? (
          <div className="grid grid-cols-3 mini:grid-cols-4 gap-2">
            {filteredExtraStickers.map(({ name, code, selected }, index) => (
              <Cell
                key={index}
                label={code!}
                selected={selected}
                onChange={(selected) =>
                  handleSelected(selected, code, "extras")
                }
              />
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-3 mini:grid-cols-4 gap-2">
            {filteredMissingStickers.map(({ name, code, selected }, index) => (
              <Cell
                key={index}
                label={code!}
                selected={selected}
                onChange={(selected) =>
                  handleSelected(selected, code, "missing")
                }
              />
            ))}
          </div>
        )}
      </div>
      <div className="pt-6 pb-8 w-full text-center text-stone-300 dark:text-stone-600 text-xs border-t border-stone-200 dark:border-stone-750">
        El álbum de{" "}
        <a href="https://wip.santiagomoreno.co" className="underline">
          Santiago Moreno
        </a>
      </div>
    </div>
  );
};

export const query = graphql`
  query List {
    allGoogleRepetidasSheet {
      nodes {
        id
        name
        code
      }
    }
    allGoogleFaltantesSheet {
      nodes {
        id
        name
        code
      }
    }
  }
`;

export default IndexPage;

export const Head: HeadFC = () => <title>Monitas | Santiago Moreno</title>;
