import React, { useState } from "react";

interface CellProps {
  label: string;
  selected: boolean;
  onChange: (selected: boolean) => void;
}

const Cell: React.FC<CellProps> = ({ label, selected, onChange }) => {
  const [isSelected, setSelected] = useState<boolean>(false);

  const handleSelected = () => {
    onChange(!selected);
  };

  return (
    <div
      className={`cursor-pointer py-3 px-2 w-full flex justify-center ${
        selected
          ? `bg-lime-300 text-lime-900`
          : `bg-white dark:bg-stone-700 text-stone-800 dark:text-stone-50`
      }  rounded-full inline-flex text-sm   font-medium select-none`}
      onClick={handleSelected}
    >
      {label}
    </div>
  );
};

export default Cell;
